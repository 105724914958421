<template>
  <div class="not-found">
    <div class="title-container">
      <h2>404 - Page non trouvée</h2>
    </div>

    <div class="content-body">
      <img
        alt="TeriDerp qui fracasse son clavier"
        class="notfound"
        src="/img/teriderp-smash.gif"
      >

      <p>
        La ressource demandée semble ne pas exister.<br>
        <NuxtLink to="/">
          Retour à l'accueil
        </NuxtLink>
      </p>
    </div>
  </div>
</template>
